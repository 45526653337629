
.wizytowka {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
  
  .tlo {
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #45AEB9;
    /* opacity: 0.7; */
    /* height: 94%; */
    width: 90%;
    margin: auto;
  }
  
  .button {
    /* position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #F15823;
    opacity: 0.5; */
    color: white;
    padding: 2px;
    font-weight: 600;
    font-size: calc(10px + 1vmin);
    border: none;
    cursor: pointer;
    background-color: black;
    justify-content: center;
    text-align: center;
    /* width: 300px;
    height: 40px; */
    position: absolute;
    right: 0px;
    bottom: 10px;
  }
  
  .wizytowka .button:hover {
    color: #45AEB9;
  }

  #icon {
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: whitesmoke;
    width: 60px;
    height: 60px;
    margin: 8px;
    float: left;
  }

  .button2 {
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: flex;
    flex-direction: row;
  }

  .button3 {
    /* padding: 10px; */
    cursor: pointer;
  }

  .pwaPromptShareIcon {
    flex: 0 0 auto;
    height: 30px;
    /* margin-right: 32px; */
    width: 25px;
  }

  .pwaPromptHomeIcon {
    flex: 0 0 auto;
    height: 30px;
    /* margin-right: 32px; */
    width: 25px;
  }

  /* .installModal {
    position:fixed;
   top:auto;
   right:auto;
   left:auto;
   bottom:0;
  } */